import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor{

  
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const userToken ='eyJraWQiOiJvS2hVaW9XX0NMaExpWllSeUVQUUN2X3N0cS1DTTlYS3NaRlZKa185Y1hRIiwiYWxnIjoiUlMyNTYifQ.eyJ2ZXIiOjEsImp0aSI6IkFULmdRc09uUURHa1V2cFFQUE9XU0FwRFNEMmpzcVNsVzl6OUt0V0U1WHBBMmsiLCJpc3MiOiJodHRwczovL2FlbmV0d29ya3Mub2t0YXByZXZpZXcuY29tL29hdXRoMi9kZWZhdWx0IiwiYXVkIjoiYXBpOi8vZGVmYXVsdCIsImlhdCI6MTYxMjg2NjE2MywiZXhwIjoxNjEyODY5NzYzLCJjaWQiOiIwb2FzOXl2cjRlSVFHbGtlVjBoNyIsInVpZCI6IjAwdXEyZDFlZnpabm44czc2MGg3Iiwic2NwIjpbIm9wZW5pZCJdLCJzdWIiOiJNYWRodWthci5QYXJ1cGF0aUBhZW5ldHdvcmtzLmNvbSJ9.q1RgUU448nedPYGUNPHwlTYo4W4nOoVtm9VDyyFLdeV2XMi6mTorAj5bbG88XJd1r8gU50PYehL44EZCBAHin8qVoXjzkMCOpZNFxU2AdCVBEgEhlW5PKOHQjVoOLNnPc4hAgiNqekEZUEbMhj3N4c9wZuY1eo58VA6eh-1qSbX6zhzwLQudL8xKwtTzIlvyBKMy_6GPhEBxDBsHiDf5mk75XcuHPnrvxqHsWrQdn-SIPg7a_uMLe5HVhO7iJI8g2YTubP48Y9o0zN9nOh9_fvTIkiaM1fshKGHhJX3CqstR-G2r_4BdiTv82pFarga9H7QGgEbdFeX5D9FVEBsqSg';
    const modifiedReq = req.clone({
      headers: req.headers.set('X-AMZ-USR', userToken),
    });
    return next.handle(modifiedReq);
  }
}
